.vertical-center {
    margin: 0;
    position: absolute;
    top: 300px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.center-inner {
    display: table;
    margin: 0 auto;
}

.center-outer {
    width: 100%;
}
.center {
    margin: auto;
    text-align: center;
}
.mapStyle {
    width: 100%;
    height: 100%;
}

.map-container {
    position: relative;
    width: 100%;
    height: 100%;
}
